import React, { useEffect } from "react";
import { Icon, Button } from '@nutui/nutui-react';
import { withRouter } from "react-router-dom";
import { verifyOriderAxios } from "@/commons/fetch/order";
import { getUrlParam } from "@/commons/utils/helps";
import { useState } from "react";
import SUCCESSICON from "@/static/order/icon_check_succes.png";
const OrderIdSuccess = (props) => {
    const [status, setStatus] = useState(false)
    const getDate = async (id) => {
        let res = await verifyOriderAxios({ orderid: id });
        if (res.success) {
            setStatus(true);
        }
    }
    useEffect(() => {
        if (getUrlParam("orderid")) {
            getDate(getUrlParam("orderid"));
        }
    }, [])
    return (
        <div style={{
            height: "100%", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center",
            width: "6.86rem",
            height: "5.24rem",
            background: "#FFFFFF",
            borderRadius: "0.16rem",
            margin: "0.2rem auto 0",
            overflow: "hidden"
        }}>
            <div style={{ marginTop: "0.6rem" }}>
                {
                    status && <img src={SUCCESSICON} style={{ width: "1.2rem" }} alt="" />
                }
                {
                    !status && <Icon name={status ? "success" : "failure"} style={{ fontSize: "0.8rem", color: status ? "green" : "red" }}></Icon>
                }

            </div>
            <p style={{
                marginTop: "0.3rem", fontSize: "0.36rem", fontWeight: 600, color: "#000000",
            }}>核销{status ? "成功" : "失败"}</p>
            {
                status ? <p style={{ marginTop: "0.2rem",  width: "60%" ,
                width: "5.60rem",
                fontSize: "0.24rem",
                fontweight: 400,
                color: "#999999",
            }}>成功后，垂钓者可自行前往垂钓。当订单时间完成后30分钟内，用户付款放入您的账户余额中哦</p> : ""
            }
            <Button style={{
                marginTop:"0.6rem",
                width: "3rem",
                background: "linear-gradient(90deg, #57A1FF 0%, #2C68FF 100%)",

            }} 
            type={"info"}
            onClick={() => {
                props.history.push("/console/order")
            }}>
                查看我的订单
            </Button>
        </div >
    )
}

export default withRouter(OrderIdSuccess);