import React, { memo, useEffect, useMemo, useState } from "react";
import { Grid, GridItem, Button, Toast, Dialog } from '@nutui/nutui-react';
import { Input } from '@nutui/nutui-react';
import { withRouter } from "react-router-dom";
import { merchantWithdrawAxios } from "@/commons/fetch/access";
import { useRequest } from "ahooks";
import "./meAmountStyles.css";

const MineBannerComponent = (props) => {
    const { loading, run, runAsync } = useRequest(merchantWithdrawAxios, {
        manual: true
    });
    const { data, callback } = props;
    const [list, setList] = useState(
        [
            { name: "销售累计", value: 0, unit: "元", btn: false },
            { name: "已完成订单数", value: 0, unit: "个", btn: false },
            { name: "待核销金额", value: 0, unit: "元", btn: false },
            { name: "可提现金额", value: 0, unit: "元", btn: true, btnText: "提现" },
        ]
    )
    useEffect(() => {
        if (data && (data.OrderMoney || data.OrderCount || data?.VerifyMoney || data.Money)) {
            setList([
                { name: "销售累计", value: data.OrderMoney / 100, unit: "元", btn: false },
                { name: "已完成订单数", value: data.OrderCount, unit: "个", btn: false },
                { name: "待核销金额", value: data?.VerifyMoney / 100, unit: "元", btn: false },
                { name: "可提现金额", value: data.Money / 100, unit: "元", btn: true, btnText: "提现" },
            ])
        }
    }, [data])
    const [amountValue, setAmountValue] = useState(1)
    const [visible, setVisible] = useState(false)
    const ItemRender = ({ item, index }) => {
        return (
            <div>
                <h2 className="h2"><span></span>{item.name}</h2>
                <div style={{display:"flex",justifyContent:"center",alignItems: "flex-end"}}>
                    <span className={`amount`}>{item.value}</span>
                    <span className="unit">/{item.unit}</span>
                </div>

                {
                    item?.btn && <div className="btn">
                        <Button
                            disabled={visible || (data && (data.Money / 100) === 0)}
                            onClick={() => {
                                if (data?.BankNo && data?.BankNo?.length > 0) {
                                    // setVisible(true)
                                    merchantWithdraw()
                                } else {
                                    props.history.push("/console/bindBankCard")
                                }
                            }
                            } type="primary">{item.btnText}</Button>
                    </div>
                }

            </div>
        )
    }

    // 提现金额输入
    const merchantWithdraw = async () => {
        console.log("确定提现,您要提现的金额为：", { money: amountValue });
        setVisible(true);
        try {
            // let res = await runAsync({ money: parseInt(amountValue) * 100 });
            let res = await runAsync();
            if (res.status_code === 0 && res.success) {
                window.Messages.open("提交申请成功", "success");
                setVisible(false);
                callback()
                // window.location.reload();
            }

        } catch (error) {
        }
    }

    return (
        <Grid gutter={4} columnNum={2} center={false}>
            {
                list.map((item, key) => {
                    return (
                        <GridItem key={key} text={<ItemRender item={{ ...item }} index={key} />} />
                    )
                })
            }
            {/* <Dialog
                title="提现金额"
                visible={visible}
                lockScroll
                onOk={merchantWithdraw}
                onCancel={() => setVisible(false)}
            >
                <div className="InputNumber">
                    {
                        <Input
                            defaultValue={Number(amountValue)}
                            placeholder="请输入提现金额"
                            border={false}
                            type={"number"}
                            change={(val) => {
                                console.log(val)
                                setAmountValue(val)
                            }}
                        />
                    }

                    <Button size="small" type="primary" onClick={() => {
                        setAmountValue(1000)
                    }}>全部提现</Button>

                </div>
            </Dialog> */}
        </Grid>
    )
}
export default memo(withRouter(MineBannerComponent));