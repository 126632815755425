import React from "react";
import { Button, Overlay } from "@nutui/nutui-react";
import "./style.scss"
const DialogComponent = (props) => {
    const { visible, title, desc, onClose } = props;
    return (
        <Overlay visible={visible} >
            <div className="WrapperStyle">
                <div className="ContentStyle">
                    <h3 className="title">{title}</h3>
                    <div>
                        <p style={{ fontSize: "14px", marginBottom: "8px" }}>{desc}</p>
                        {/* <Input change={(e)=>console.log(e)} placeholder="请输入取消原因" style={{ border: "1px solid #eee", borderRadius: "12px" }} /> */}
                    </div>
                    <div style={{ margin: "20px 0 0", display: 'flex', justifyContent: "flex-end", }}>
                        <Button onClick={() => onClose(false)} type="default">取消</Button>
                        <Button type="primary" onClick={() => onClose(true)} style={{ marginLeft: "20px" }}>确定</Button>
                    </div>
                </div>
            </div>
        </Overlay>
    )
}

export default DialogComponent;