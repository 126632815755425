export const InfiniteUlStyle = {
    height: 'calc(100% - 30px)',
    width: '100%',
    padding: '0',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop:"20px"
}

export const InfiniteLiStyle = {
    fontSize: '14px',
    padding:"10px 20px",

}

export const CellInfiniteUlStyle = {
    // flex: 1
    height: "100%"
}

export const InfiniteLiItemStyle = {
    boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
    padding: "10px 20px",
    borderRadius: "10px"
}