import React, { memo, useEffect, useLayoutEffect } from "react";
import NoneFishPool from "./component/NoneFishPool.jsx";
import { Empty, Icon, Radio, PullToRefresh } from '@nutui/nutui-react';
import "./index.scss";
import { fishpondMerchantListAxios, fishPondsOnShelvesAxios, fishPondsOffShelvesAxios, fishPondsDeleteAxios } from "@/commons/fetch/home";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { fishPondStatus } from "../../commons/utils/helps";
import DialogComponent from "../../components/Dailog";
import { wxScanAxios } from "@/commons/fetch/access";
import { useRequest } from 'ahooks';
import { ImagePreview, WaterMark } from '@nutui/nutui-react';
import EDITICON from "@/static/home/Icon_edit.png";
import DELETEICON from "@/static/home/Icon_delete.png";
let time;
const HomeIndexComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("open");
  const [onload, setOnload] = useState(false);
  const wxFun = useRequest(wxScanAxios, {
    manual: true
  });
  const [showPreview, setShowPreview] = useState(false);

  const [data, setData] = useState([])
  const getData = async () => {
    setOnload(true)
    let res = await fishpondMerchantListAxios({ ipp: 0, page: 0 });
    setOnload(false)
    if (res.success) {
      setData(res.data.fishponds);
    }
  }
  const btnClick = async (item) => {
    if ([0, "0"].includes(item.Status)) {
      setOpen(item);
      setType("open");
      return
    }
    try {
      let res = await fishPondsOffShelvesAxios({ fishpondid: item.Fishpondid });
      if (res.success) {
        getData();
        return;
      }
      getData();
      window.Messages.open("下架失败", "error");
    } catch (error) {
      getData();
      window.Messages.open(error?.msg ?? "下架失败", "error");
    }


  }
  const onClose = async (e) => {
    if (type === "open") {
      if (e) {
        try {
          let res = await fishPondsOnShelvesAxios({ fishpondid: open.Fishpondid });
          if (res.success) {
            window.Messages.open("请等待审核哦~", "success");
            setOpen(false);
            getData();
            return;
          }
          window.Messages.open("上架失败", "error");
        } catch (error) {
          window.Messages.open(error?.msg ?? "上架失败", "error");
        }
        return
      }
      getData();
      setOpen(false);
    } else if (type === "del") {
      if (e) {
        try {
          let res = await fishPondsDeleteAxios({ fishpondid: open.Fishpondid });
          if (res.success) {
            setOpen(false);
            getData();
            window.Messages.open("删除成功", "success");
            return;
          }
          window.Messages.open("删除失败", "error");
        } catch (error) {
          window.Messages.open(error?.msg ?? "删除失败", "error");
        }
        return
      }
      setOpen(false);
    }
  }
  const handleChange = (item) => {
    btnClick(item);
  }
  const hideFn = (e) => {
    setShowPreview(false)
  }

  useEffect(() => {
    if (showPreview && showPreview.length > 0) {
      let domViewBox = document.getElementById("homeImagePreview");
      time = setTimeout(() => {
        let domView = domViewBox && domViewBox.getElementsByClassName("nut-imagepreview-index");
        console.log(domView[0])
        domView[0].innerHTML = "长按图片，保存二维码"
      }, 100);
    }

    return ()=> time && clearTimeout(time);
  }, [showPreview])


  React.useEffect(() => {
    getData()
  }, []);
  return (
    <div className="HomeIndexComponent">
      <PullToRefresh onRefresh={getData}>
        <div id="homeImagePreview">
          <ImagePreview
            images={Boolean(showPreview) ? showPreview : []}
            show={Boolean(showPreview)}
            onClose={hideFn}
          />
          <WaterMark
            className="mark1"
            zIndex={1}
            content="史诗鱼"
          />
        </div>
        {
          data.length === 0 && <Empty description="暂无鱼塘" imageSize={100} />
        }
        <div className="muneDataItem">
          {data.map((item, index) => {
            return (
              <div className="homeDataItem" key={index} >
                <div className="homeDataItemTop">
                  <div className="left">
                    <div className="homeDataItemImage"><img src={item?.Urls?.[0]} alt="" style={{ width: "100%", height: "100%" }} /></div>
                    <div className="homeDataItemCenterName">
                      <p>{item.Name}</p>
                    </div>
                  </div>
                  <div className="right">
                    {
                      item.Qrcode && item.Status === 2 && (
                        <div
                          style={{ marginRight: "0.05rem" }}
                          className="itemEdit"
                          onClick={() => {
                            setShowPreview([{ src: item?.Qrcode }]);
                          }}>
                          <Icon name="share" style={{ fontSize: "0.32rem", color: "#b5abab" }} />
                        </div>
                      )
                    }
                    <div
                      className="itemEdit"
                      onClick={() => {
                        props.history.push({
                          pathname: `/console/home-edit`,
                          state: item
                        })
                      }}>
                      <img src={EDITICON} style={{ width: "0.44rem" }} alt="" />
                    </div>
                    <div
                      className="itemDelete"
                      onClick={(e) => {
                        setType("del");
                        setOpen(item);
                        e.stopPropagation();
                      }}
                    >
                      <img src={DELETEICON} style={{ width: "0.44rem" }} alt="" />
                    </div>
                  </div>
                </div>
                <div className="homeDataItemCenter">
                  <div className="left">
                    <div className="updateTime">
                      <p className="title">更新时间：</p>
                      <p className="subTitle">2022-10-28  12:00</p>
                    </div>
                    <div className="status">
                      <p className="title">鱼塘状态：</p>
                      {
                        ["1", 1].includes(item.Status) && <p className="subTitle">{fishPondStatus(item.Status)}</p>
                      }
                      {
                        !["1", 1].includes(item.Status) && !onload && <Radio.RadioGroup
                          onChange={(e) => handleChange(item)}
                          style={{ display: 'flex', alignItems: "center" }}
                          value={item.Status}>
                          <Radio value={2}>公开</Radio>
                          <Radio style={{ marginRight: "0.3rem" }} value={0}>下线</Radio>
                        </Radio.RadioGroup>
                      }
                      {/* {
                      !["1", 1].includes(item.Status) && <Button onClick={(e) => {
                        btnClick(item);
                        e.stopPropagation();
                      }} type="primary" style={{ width: "100px", marginLeft: "20px" }}>{item.Status === 0 ? "公开" : "下线"}</Button>
                    } */}
                    </div>
                  </div>
                  <div className="right">
                    <img src={item?.Urls?.[0]} alt="" style={{ width: "1.4rem", height: "1.4rem", display: "block" }} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </PullToRefresh>
      <NoneFishPool onChange={() => {
        props.history.push("/console/home-edit")
      }}></NoneFishPool>
      <DialogComponent
        visible={Boolean(open)}
        title={type === "open" ? "公开鱼塘信息" : "删除鱼塘信息"}
        desc={type === "open" ? "是否公开鱼塘？鱼塘公开后，会进行审核，审核成功后，用户将能搜索到您的鱼塘哦~" : "您确定删除鱼塘？"}
        onClose={onClose}
      />
    </div>
  );
};

export default memo(withRouter(HomeIndexComponent));
