import React, { memo, useEffect, useMemo, useState } from "react";

import { withRouter, Route, Switch, } from "react-router-dom";
import { NavBar, Icon, Tabbar, TabbarItem } from '@nutui/nutui-react';
import "./LayoutStyles.scss";
import RouterList from "../routers/console";
import { backList, mineQRCode, headerTitle } from "./back";
import SCAN_QR_CODE from "../static/image/ScanQRCode.png";
const LayoutComponent = (props) => {
    const [visible, setVisible] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        switch (props?.location?.pathname) {
            case "/console/home":
                setVisible(0);
                break;
            case "/console/order":
                setVisible(1);
                break;
            case "/console/mine":
                setVisible(2);
                break;
            default:
                setVisible(0);
                break;
        }
        setLoading(false);
    }, [props?.location?.pathname])

    const footerRender = useMemo(() => {
        return (
            <Tabbar
                // visible={Number(visible)}
                activeVisible={visible}
                className="footer" tabSwitch={(child, idx) => {
                    props.history.push(child?.props?.path)

                }}>
                {
                    RouterList.map((item, key) => {
                        if (item?.isHide) return;
                        return (
                            <TabbarItem key={key} path={item?.link} tabTitle={item?.label} icon={item?.icon} />
                        )
                    })
                }
            </Tabbar>
        )
    }, [visible])
    if (loading) return "loading ...";
    return (
        <div className="boxWrap">
            {
                window.location.pathname !== "/h5/console/mine" && <NavBar
                    className="header"
                    title={headerTitle()}
                    // onClickTitle={(e) => props.history.goBack(-1)}
                    onClickBack={(e) => {
                        if (backList.includes(props?.location?.pathname)) {
                            if (props?.location?.pathname === "/console/home-edit") {
                                props.history.push("/console/home");
                                return;
                            }
                            props.history.goBack(-1)

                        } else if (mineQRCode == props?.location?.pathname) {
                            localStorage.removeItem("qrInfo");
                            localStorage.setItem("qrInfo", JSON.stringify({ id: "qr" }));
                            window.location.href = window.location.origin + "/h5/console/qr";

                        }
                    }}
                    // onClickRight={(e) => alert('icon')}
                    leftText={
                        backList.includes(props?.location?.pathname) ? "返回" :
                            mineQRCode == props?.location?.pathname ? <img src={SCAN_QR_CODE} alt="" style={{ width: "20px" }} /> : ""
                    }
                    leftShow={backList.includes(props?.location?.pathname)}
                >
                </NavBar>
            }

            <div className="content">
                <Switch>
                    {
                        RouterList.map((item, key) => {
                            return (
                                <Route
                                    key={key}
                                    path={item?.link}
                                    component={item?.component}
                                />
                            )
                        })
                    }
                </Switch>
            </div>
            {footerRender}
        </div>
    )
}



export default memo(withRouter(LayoutComponent));