import axios from "axios";
import { baseURL } from "../utils/url";
axios.defaults.timeout = 100000;
axios.defaults.baseURL = baseURL;
//http request 拦截器
axios.interceptors.request.use(
    (config) => {
        let json = {
            "Content-Type": "application/json",
        };
        if (localStorage.getItem("token")) {
            json["Authorization"] = "Bearer " + localStorage.getItem("token");
        }
        config.headers = json;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then((response) => {
            if (response?.data?.status_code === -1 && response?.data?.msg === "Forbidden") {
                window.Messages.open("error");
                window.location.href = `${window.location.origin}/h5/login`
                reject(false);
            }
            resolve(response?.data);
        })
            .catch((error) => {
                if(error?.response?.status===401){
                    window.Messages.open("error");
                    window.location.href = `${window.location.origin}/h5/login`
                }
                reject(error);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                if (response?.data?.status_code === -1 && response?.data?.msg === "Forbidden") {
                    window.Messages.open("error");
                    window.location.href = `${window.location.origin}/h5/login`
                    reject(false);
                }
                resolve(response?.data);
            },
            (error) => {
                if(error?.response?.status===401){
                    window.Messages.open("error");
                    window.location.href = `${window.location.origin}/h5/login`
                }
                reject(error);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//统一接口处理，返回数据
export default function (fecth, url, param, config) {
    return new Promise((resolve, reject) => {
        switch (fecth) {
            case "get":
            case "GET":
                get(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
                break;
            case "post":
            case "POST":
                post(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
                break;
            default:
                break;
        }
    });
}