import React, { memo, useEffect } from "react";
import { Cell, CellGroup, Empty } from '@nutui/nutui-react'
import { getAnnouncementAxios } from "@/commons/fetch/mine";
import { useRequest } from 'ahooks';
// import { DotLoading } from 'antd-mobile'


const AnnouncementComponent = () => {
    const { data, loading, runAsync } = useRequest(getAnnouncementAxios, {
        manual: true,
    });
    useEffect(() => {
        runAsync({ type: 1 })
    }, [])
    console.log(data, loading)
    return (
        <CellGroup
        >
            {/* {
                loading &&   <DotLoading color='primary' />
            } */}
            {
                !loading && !data?.data && <Empty description="暂无公告" imageSize={100} />
            }
            {
                !loading && data?.data && data?.data?.length > 0 && <div>
                    {
                        data?.data.map((item, index) => {
                            return (
                                <Cell key={index} title={(index + 1) + "、" + item.doc} />
                            )
                        })
                    }
                </div>
            }

        </CellGroup>
    )
}

export default memo(AnnouncementComponent);