import React, { useEffect } from "react";
import { Form, Input, Cell, Button, Notify } from '@nutui/nutui-react'

const MessageComponent = () => {
    useEffect(()=>{
        window.Messages = {
            open:(msg,type)=>{
                switch (type) {
                    case "primary":
                        Notify.primary(msg)
                        break;
                    case "success":
                        Notify.success(msg)
                        break;
                    case "danger":
                    case "error":
                        Notify.danger(msg)
                        break;
                    case "warn":
                        Notify.warn(msg)
                        break;
                    default:
                        break;
                }
            }
        }
    },[])

    return ""
}

export default MessageComponent;