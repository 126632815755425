import React, { memo } from "react";
import { Avatar } from '@nutui/nutui-react';
import "./meBannerStyles.css";
import ICON from "@/static/mine/my_icon_notice.png";
import { withRouter, Redirect } from "react-router-dom";

const MineBannerComponent = (props) => {
    const { data } = props;
    return (
        <div className="meBannerBackground">
            <div className="div">
                {
                    <img src={data?.HeadPic} style={{ width: "1.2rem", borderRadius: "50%" }} title="" />
                }
                <p className="account">
                    <p>{data?.Name ?? data?.Phone ?? "-"}</p>
                </p>
            </div>
            <div className="noft" onClick={() => props.history.push("/console/announcement")}>
                <img src={ICON} className="image" alt="" />
                <p className="p">
                    通知：关于鱼塘更新的公告！
                </p>
            </div>
        </div>
    )
}
export default memo(MineBannerComponent);