import React, { useState } from "react";
import { useRequest, useDebounceEffect } from 'ahooks';
import { userDetailAxios, wxScanAxios } from "@/commons/fetch/access";
import { useEffect } from "react";
import { Button } from "@nutui/nutui-react";
import { withRouter } from "react-router-dom";
import { getUrlParam } from "../../commons/utils/helps";
const QrComponent = (props) => {
    const [types, setTypes] = useState(null);
    const [qrurl, setQrurl] = useState(null);
    const wxFun = useRequest(wxScanAxios, {
        manual: true
    });
    const getSac = async () => {
        let res = await wxFun.runAsync();
        window.wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: 'wx5747454840b12947', // 必填，公众号的唯一标识
            timestamp: Number(res.data?.timestamp), // 必填，生成签名的时间戳
            nonceStr: res?.data?.nonce,  // 必填，生成签名的随机串
            signature: res.data?.sign,// 必填，签名
            jsApiList: ["scanQRCode", "chooseImage", "onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的 JS 接口列表
        });
        window.wx.ready(function () {
            window.wx.scanQRCode({
                needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    // setResult( "https://h5.epicfish.cn/h5/console/success?oederid=" + JSON.stringify(result))
                },
                error: function (error) {
                    console.log(error)
                }
            });
        });
    }
    const shareMenu = async (url) => {
        let res = await wxFun.runAsync();
        window.wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: 'wx5747454840b12947', // 必填，公众号的唯一标识
            timestamp: Number(res.data?.timestamp), // 必填，生成签名的时间戳
            nonceStr: res?.data?.nonce,  // 必填，生成签名的随机串
            signature: res.data?.sign,// 必填，签名
            jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的 JS 接口列表
        });
        window.wx.ready(function () {
            window.wx.updateAppMessageShareData({
                title: '111', // 分享标题
                desc: '2222', // 分享描述
                link: 'http://localhost:3000/h5/console/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: url ?? qrurl, // 分享图标
                success: function () {
                    // 设置成功
                }
            })
        });
    }

    useEffect(() => {
        const info = localStorage.getItem("qrInfo");
        if (info) {
            const qrInfo = JSON.parse(info);
            if(qrInfo['id'] === "share"){
                setQrurl(qrInfo["url"]);
            }
            setTypes(qrInfo['id']);
        }

        return () => localStorage.removeItem("qrInfo");
    }, []);

    useEffect(() => {
        if (types === "qr") {
            getSac();
        } else if (types === "share") {
            if (getUrlParam("url")) {
                shareMenu();
            }
        }
    }, [types]);

    return (
        <div style={{ display: "flex", paddingTop: "1rem", justifyContent: 'center' }} >
            {types === "qr" && <Button onClick={getSac}>重新扫描</Button>}
            {types === "share" && <Button onClick={shareMenu}>分享</Button>}

            <Button
                onClick={() => {
                    props.history.goBack(-1)
                }}
                style={{
                    marginLeft: "0.2rem",
                    width: "2rem", border: "none", height: "40px", color: "#fff",
                    background: "linear-gradient(90deg, #57A1FF 0%, #2C68FF 100%)", borderRadius: "0.45rem"
                }}>返回首页</Button>
        </div>
    )
}

export default withRouter(QrComponent)