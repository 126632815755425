import React, { memo, useState, useEffect } from "react";

import { withRouter, Route, Switch } from "react-router-dom";
import { NavBar, Tabbar, TabbarItem, Tabs, TabPane, Button, Input, Calendar, Overlay, Empty, PullToRefresh } from "@nutui/nutui-react";
import "./order.scss";
import { orderStatus } from "../../commons/utils/helps";
import { forderMerchantListAxios, forderMerchantCancelOriderAxios } from "../../commons/fetch/order";
import { useRequest } from 'ahooks';

const OrderComponent = (props) => {
    const { data, error, loading, runAsync } = useRequest(forderMerchantListAxios, {
        manual: true,
    });
    const cancelOrder = useRequest(forderMerchantCancelOriderAxios, {
        manual: true,
    });

    const [listData, setList] = useState([]);
    const [tabValue, setTabValue] = useState('0');//待核销/已完成/已退款  2   3    8
    const [date3, setDate3] = useState('');
    const [isVisible, setIsVisible] = useState(false)
    const [startValue, UpdateStartValue] = useState('')
    const [endValue, UpdatEndeValue] = useState('')
    const tabList = [
        { title: "待核销" },
        { title: "已完成" },
        { title: "已退单" },
    ];
    const [visible, setVisible] = useState(false)

    const openSwitch = () => {
        setIsVisible(true)
    }

    const closeSwitch = () => {
        setIsVisible(false)
    }

    const setChooseValue = (param) => {
        console.log("选择日期param", param);
        console.log("选择开始日期param", param[0]);
        console.log("选择结束日期param", param[1]);
        // setDate3(param[3])
        UpdateStartValue(param[0][3]) // 设置开始时间
        UpdatEndeValue(param[1][3]) // 设置结束时间
    }

    // 页面数据
    const getData = async (num) => {
        try {
            let res = await runAsync({ ipp: 0, page: 0, status: ["0", 0].includes(num) ? 2 : (["1", 1].includes(num) ? 3 : 8) });
            console.log("list 页面数据", res);
            if (res.success) {
                setList(res.data.orders)
            }
        } catch (error) {

        }
    };
    // 退单
    const refuseOrder = (item) => {
        console.log("退单", item);
        setVisible(item)
    }
    // 点击时间
    const click = (value) => {
        console.log('click:', value)
        setIsVisible(true)
    }
    const cancelOriderFun = async () => {
        try {
            let res = await cancelOrder.runAsync({ orderid: visible.Orderid });
            if (res.success && res.status_code === 0) {
                window.Messages.open("退单成功", "success");
                setVisible(false);
                getData(tabValue)
                return
            }

        } catch (error) {
            window.Messages.open(error.message, "error");

        }
    }
    useEffect(() => {
        getData(tabValue)
    }, [])
    const OrderItem = (props) => {
        if (listData.length === 0) {
            return <Empty description="暂无订单" imageSize={100} />
        }
        return listData?.map((item, index) => {
            return (
                <div className="card" key={index}>
                    <div className="itemHeader">
                        <div className="itemHeaderLeft">
                            <div className="itemHeaderImage">
                                <img src={item.OrderUrl} style={{ width: "100%", height: "100%" }} alt="" />
                            </div>
                            <p className="itemHeaderName">{item.FishpondName}</p>
                        </div>
                        <p className="itemHeaderOrderStatus">{orderStatus(item.OrderStatus)}</p>
                    </div>
                    <div className="itemContent">
                        <div className="itemContentLeft">
                            <img src={item.OrderUrl} style={{ width: "100%", height: "100%" }} alt="" />
                        </div>
                        <div className="itemContentRight">
                            <div className="itemContentRightP itemContentRightP1">
                                <p className="title">下单时间：</p>
                                <p className="subTitle">{item.CreatedAt}</p>
                            </div>
                            <div className="itemContentRightP">
                                <p className="title">总价：</p>
                                <p className="subTitle">￥{item.Money / 100}</p>
                            </div>
                        </div>

                    </div>
                    {
                        [2, "2"].includes(item.OrderStatus) && (
                            <div className="itemFooter">
                                <Button type="default" className="btn1" onClick={() => refuseOrder(item)}>退单</Button>
                                {/* <Button type="info" className="btn2" onClick={()=> window.Messages.open("敬请期待~","warn")}>订单详情</Button> */}
                            </div>
                        )
                    }

                </div>
            )
        });
    };

    return (
        <div className="orderBoxWrap">
            <PullToRefresh onRefresh={() => getData(tabValue)}>
                <div className="orderCentent">
                    <Tabs tabStyle={{ position: 'sticky', top: '0px', zIndex: 1 }} value={tabValue} onChange={({ paneKey }) => {
                        setTabValue(paneKey)
                        getData(paneKey)
                        UpdateStartValue('') // 设置开始时间
                        UpdatEndeValue('') // 设置结束时间
                    }}>
                        {
                            tabList?.map((item, index) => {
                                return (
                                    <TabPane title={item.title} className="tabpane" key={index}>
                                        {/* <div className="dateChange">
                                        <Input
                                            // label="文本"
                                            placeholder="开始日期"
                                            defaultValue={startValue}
                                            rightIcon="date"
                                            onClick={click}
                                        /> 
                                        <span>至</span>
                                        <Input
                                            // label="文本"
                                            placeholder="结束日期"
                                            defaultValue={endValue}
                                            rightIcon="date"
                                            onClick={click}
                                        />
                                    </div> */}
                                        <OrderItem data={item}></OrderItem>
                                    </TabPane>
                                );
                            })
                        }
                    </Tabs>
                    {/* <Calendar
                    visible={isVisible}
                    // defaultValue={date3}
                    type="range"
                    // startDate="2022-01-01"
                    // endDate="2022-09-10"
                    onClose={closeSwitch}
                    onChoose={setChooseValue}
                /> */}
                    {/* <Switch>
                    {RouterList.map((item, key) => {
                        return (
                            <Route key={key} path={item?.link} component={item?.component} />
                        );
                    })}
                </Switch> */}
                </div>
            </PullToRefresh>
            <Overlay visible={Boolean(visible)} >
                <div className="WrapperStyle">
                    <div className="ContentStyle">
                        <h3 className="title">取消订单</h3>
                        <div>
                            <p style={{ fontSize: "14px", marginBottom: "8px" }}>是否取消订单？</p>
                            {/* <Input change={(e)=>console.log(e)} placeholder="请输入取消原因" style={{ border: "1px solid #eee", borderRadius: "12px" }} /> */}
                        </div>
                        <div style={{ margin: "20px 0", display: 'flex', justifyContent: "flex-end", }}>
                            <Button onClick={() => setVisible(false)} type="default">取消</Button>
                            <Button type="primary" onClick={cancelOriderFun} style={{ marginLeft: "20px" }}>确定</Button>
                        </div>
                    </div>
                </div>
            </Overlay>
        </div>
    );
};

export default memo(withRouter(OrderComponent));
