import React from "react";
import { CellGroup, Cell } from '@nutui/nutui-react';
import { Button } from '@nutui/nutui-react';
import { serialization } from "@/commons/utils/helps";
import "./meMenuStyles.css";
const MenuComponent = (props) => {
    const { data } = props;
    if (!data) return "";
    const { BankNo, BankUrl, RealName,Phone } = data;
    return (
        <CellGroup
            className="CellGroupTitle"
        >
            <Cell
                size="large"
                className="authenticity"
                title="绑定银行卡"
                desc={<>
                    {
                        BankNo ? <span>{BankNo.slice(0,4)}**********{BankNo.slice(14)}</span> : <Button type="primary">去绑定</Button>
                    }
                </>}
                to={`/console/bindBankCard${BankNo ? serialization({ bankno: BankNo, bankurl: BankUrl, realname: RealName,phone:Phone }) : ""}`}
            />
            <Cell
                title="提现记录"
                size="large"
                to="/console/withdrawalRecord"
            />
            <Cell
                title="史诗鱼官网首页"
                desc="https://www.epicfish.cn"
                isLink
                size="large"
                url="https://www.epicfish.cn"
            />
            <Cell
                size="large"
                title="平台使用说明"
                to="/console/explai"
            />
        </CellGroup>
    )
}


export default MenuComponent