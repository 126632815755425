import React from "react";
import { Icon, Button } from "@nutui/nutui-react";

export default (props) => {
  const { onChange } = props;
  return (
    <div style={{padding:"10px 20px 20px"}}>
      <Button
        type="primary"
        style={{ display: "block", margin: "0.4rem auto",border:"none" }}
        onClick={() => {
          onChange && onChange();
        }}
      >
        创建鱼塘
      </Button>
    </div>
  );
};
