import React, { useEffect, useState } from "react";
import { Form, Checkbox, Input, DatePicker, Cell, Radio, Toast, Tag, Icon, Popup, TextArea, Notify } from "@nutui/nutui-react";
import { merchantsCreatFishPonds, merchantsUpdateFishPonds, tenMapAxios } from "../../../commons/fetch/home";
import Uploader from "./Uploader";
import moment from "moment";
import { useRequest } from "ahooks";
import { withRouter } from "react-router-dom";
import "./EditFishPool.scss"
import { parseSearchToState, parseStateToSearch } from "../../citys/utils/index";
import SettlementAgreementComponent from "./SettlementAgreement";
import { API_KEY } from "../../../commons/utils/constants";
let typeButton = "";
const cu = moment().format("YYYY-MM-DD");
const EditFistPollComponent = (props) => {
  const dateilValue = props?.history?.location?.state;
  const CreateAxios = useRequest(dateilValue ? merchantsUpdateFishPonds : merchantsCreatFishPonds, { manual: true });
  const [lngLat, setLngLat] = useState({
    a: null,
    b: null
  });
  const [loading, setLoading] = useState(true)
  const [formValue, setFormValue] = useState({
    fishpondLabel: [], //鱼塘标签
    settlementAgreement: false, //用户入住协议
    longitude: "",//精度
    latitude: "",//纬度
    fishpondtype: [],
    price: "",//按小时付费的总价     300元/6小时的 300元
    hour: "",// 按小时付费的小时数    300元/6小时的 6小时
    urls: [],
    province: "",//省   
    city: "", // 市   
    area: ""
  })
  //输入框
  const [inputValue, setInputValue] = useState({
    fishpondLabel: "",
  })
  const [location, setLocation] = React.useState(null);
  const [showBottomRound, setShowBottomRound] = useState(false);
  const operation = (type, val) => {
    switch (type) {
      case "location":
        break;
      case "time":
        if (["setDesc4", "setDesc5"].includes(val)) {
          setShow3(val);
        } else {
          setShow2(val);
        }
        break;
      default:
        break;
    }
  };
  const [show2, setShow2] = React.useState("");
  const [show3, setShow3] = React.useState("");
  const [desc2, setDesc2] = React.useState("08:00:00");
  const [desc3, setDesc3] = React.useState("18:00:00");
  const [desc4, setDesc4] = React.useState(cu);
  const [desc5, setDesc5] = React.useState(cu);

  useEffect(() => {
    document.getElementsByClassName("content")[0].scrollTop = 0
    if (dateilValue) {
      setFormValue({
        ...formValue,
        fishpondLabel: dateilValue?.Tags, //鱼塘标签
        settlementAgreement: false, //用户入住协议
        longitude: dateilValue?.Longitude,//精度
        latitude: dateilValue?.Latitude,//纬度
        fishpondtype: dateilValue?.FishpondType?.split(","),
        price: dateilValue.Price / 100,//按小时付费的总价     300元/6小时的 300元
        hour: dateilValue.Hour,// 按小时付费的小时数    300元/6小时的 6小时
        // urls: dateilValue.Urls.length > 1 ? dateilValue.Urls.reverse() : dateilValue.Urls,
        urls: dateilValue.Urls.length > 1 ? dateilValue.Urls : dateilValue.Urls,
        name: dateilValue.Name,
        buyfish: dateilValue.BuyFish,
        contact: dateilValue.Contact,
        content: dateilValue.Content,
        fishs: dateilValue.Fish,
        place: dateilValue.Place,
        seatnum: dateilValue.SeatNum,
        rule: dateilValue.Rule,
        address: dateilValue.Address,
        province: dateilValue.Province,
        city: dateilValue.City,
        area: dateilValue.Area,
      })
      setLngLat({
        a: dateilValue.Latitude,
        b: dateilValue.Longitude
      })
      setLocation(dateilValue.Place);
      setDesc2(dateilValue.OpenTime);
      setDesc3(dateilValue.OverTime);
      setDesc4(dateilValue.STime);
      setDesc5(dateilValue.EndTime);
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [dateilValue])

  const confirm2 = (values, options) => {
    let callBack = null;
    if (show2) {
      if (show2 === "setDesc2") callBack = setDesc2;
      if (show2 === "setDesc3") callBack = setDesc3;
      callBack && callBack(options.map((option) => option.text).join(":"));
    } else {
      if (show3 === "setDesc4") callBack = setDesc4;
      if (show3 === "setDesc5") callBack = setDesc5;
      callBack && callBack(options.map((option) => option.text).join("-"));
    }
  };

  const onChangeSetFormValue = (type, value) => {
    if (type === "fishpondLabel") {
      if (formValue.fishpondLabel.filter(k => k === inputValue.fishpondLabel).length > 0) {
        Notify.warn("鱼塘标签不能重复")
        return false;
      } else if (inputValue.fishpondLabel === "" || inputValue.fishpondLabel.length > 20) {
        Notify.warn("鱼塘标签不能为空或超过20字符")
        return false;
      }
      let json = [...formValue.fishpondLabel];
      json.push(inputValue.fishpondLabel);
      if (json.length > 3) {
        Notify.warn("鱼塘标签不能超过三个")
        return false;
      }
      setFormValue({ ...formValue, [type]: json });
      value.stopPropagation();
      return false;
    }
    setFormValue({ ...formValue, [type]: value });
  }
  const fileListCallBack = (list) => setFormValue({ ...formValue, urls: list });
  const onFinishSubmit = async (e) => {
    let json = {
      "buyfish": formValue?.buyfish ?? "",   // 回鱼规则
      "chargetype": 0,   // 支付类型，按斤/小时
      "contact": formValue?.contact ?? "",//联系方式
      "content": formValue?.content ?? "",   //鱼塘说明
      "endtime": desc5,    //鱼塘结束时间。2023-11-01
      "fishpondtype": formValue.fishpondtype.toString(),   //鱼塘类型 黑坑/路亚等
      "fishs": formValue.fishs,        // 鱼    草鱼/鲫鱼等
      "latitude": lngLat?.a + "",//纬度
      "longitude": lngLat?.b + "",//精度
      "name": formValue.name,//鱼塘名
      "hour": formValue.hour ? parseInt(formValue.hour) : 0,          // 按小时付费的小时数    300元/6小时的 6小时
      "opentime": desc2,//营业开始时间
      "overtime": desc3,//营业结束时间
      "place": location,   // 鱼塘的地址
      "address": formValue.address,   // 鱼塘的地址
      "price": formValue.price * 100,    // 按小时付费的总价     300元/6小时的 300元
      "rule": formValue.rule,//钓场规则
      "stime": desc4,   //鱼塘开始时间。2022-11-01
      "seatnum": formValue.seatnum ? parseInt(formValue.seatnum) : 0,
      "tags": formValue.fishpondLabel.toString(),
      "province": formValue.province,//省   
      "city": formValue.city, // 市  
      "area": formValue.area, // 市  
      "urls": formValue.urls.toString(),//钓场图片  格式如下，用英文逗号区分  http://www.baidu.com/1.jpg,http://www.baidu.com/2.jpg,http://www.baidu.com/1.jpg,http://www.baidu.com/2.jpg
    }
    if (dateilValue) {
      json["fishpondid"] = dateilValue.Fishpondid
    }
    if (json.fishpondtype === "" || !json.fishs || json.latitude === "" ||
      json.longitude === "" || json.hour === "" || json.price === "" || !json.rule || json.rule === "" ||
      json.contact === "" || json.name === "" || [undefined, null].includes(json.name) || json.fishs === "" || json.rule === ""
    ) {
      window.Messages.open("请将带*的信息填写完整！", "error")
      return
    }
    if (formValue.urls.length < 4) {
      window.Messages.open("鱼塘图片至少4张哦！", "error")
      return
    }
    if (formValue.fishpondLabel.length === 0) {
      window.Messages.open("为您的鱼塘打上标签吧", "error")
      return
    }
    if (!formValue.settlementAgreement) {
      window.Messages.open("请先同意入住协议！", "error")
      return
    }
    try {
      let res = await CreateAxios.runAsync(json)
      if (res.success) {
        window.Messages.open("保存成功！", "success")
        props.history.push("/console/home")
        return
      }
      window.Messages.open(res.msg, "error")

    } catch (error) {
      window.Messages.open("保存失败！", "error")
    }
  }
  const changes = (val, type) => {
    setFormValue({ ...formValue, [type]: val })
  }
  const messageFun = (event) => {
    setTimeout(() => {
      // 接收位置信息
      var loc = event.data;
      loc && loc.lat && setLngLat({ a: loc.lat, b: loc.lng })
      loc && loc.lat && setFormValue({ ...formValue, province: loc.province })
    }, 1000);
  }
  useEffect(() => {
    const { location } = props;
    const search = location && location.search;
    const searchState = parseSearchToState(search);
    if (!searchState?.latng && !dateilValue) {
      window.addEventListener('message', messageFun, false);
    }
    if (searchState?.latng && searchState?.addr) {
      setLocation(decodeURIComponent(searchState?.addr))
      setLngLat({ a: searchState?.latng.split(",")[0], b: searchState?.latng.split(",")[1] })
    }
    if (searchState.addr && decodeURIComponent(searchState.addr) && decodeURIComponent(searchState.addr).split("省")[0]) {
      setFormValue({
        ...formValue,
        // province: decodeURIComponent(searchState.addr).split("省")[0] + "省",//省   
        city: decodeURIComponent(searchState.city), // 市   
        area: decodeURIComponent(searchState.addr)?.split?.("区")?.[0]?.split("市")?.[1] + "区" ?? "",//区
      })
    }
    return () => window.removeEventListener("message", messageFun);
  }, [])
  return (
    <div className="editFishPool">
      <iframe id="geoPage" width="0" height="0" frameborder="0" style={{ display: "none" }}
        src={`https://apis.map.qq.com/tools/geolocation?key=${API_KEY}&referer=frontend-h5`}>
      </iframe>
      <DatePicker
        title="时间选择"
        type="time"
        visible={!!show2}
        onCloseDatePicker={() => setShow2("")}
        onConfirmDatePicker={(values, options) => confirm2(values, options)}
      />
      <DatePicker
        title="日期选择"
        visible={!!show3}
        onCloseDatePicker={() => setShow3("")}
        onConfirmDatePicker={(values, options) => confirm2(values, options)}
      />
      <Form
        onFinish={onFinishSubmit}
        value={{
          name: "cascas"
        }}
      // onFinishFailed={(error) => window.Messages.open("请将信息填写完整！", "error")}
      >
        <h3 style={{ padding: "20px 20px 0" }}>基本信息</h3>

        <Cell >
          <span style={{ width: "95px", paddingLeft: "20px", display: "flex", alignItems: !location ? "center" : "flex-start" }}>
            <span style={{ color: "red" }}>*</span>
            鱼塘位置
          </span>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "25px" }}>
            <p style={{ width: "50%", paddingLeft: !location ? 0 : "0.2rem", color: !location ? "#c8c9cc" : "rgb(44, 104, 255)" }}>{location ?? "请获取定位"}</p>

            {
              !dateilValue && <button
                type="button"
                style={{
                  width: "80px", border: "none", height: "30px", color: "#fff",
                  background: "linear-gradient(90deg, #57A1FF 0%, #2C68FF 100%)",
                  borderRadius: "0.36rem",
                  border: "none"
                }}
                onClick={(e) => {
                  if(lngLat?.a && lngLat?.b){
                    window.location.href = `https://apis.map.qq.com/tools/locpicker?search=1&type=0&mapdraggable=1&coord=${lngLat.a},${lngLat.b}&backurl=${window.location.href}&key=43SBZ-IQW6I-627GS-5ILWQ-FGL37-XNFYX&referer=frontend-h5`
                  }else{
                    window.location.href = `https://apis.map.qq.com/tools/locpicker?search=1&type=0&mapdraggable=1&backurl=${window.location.href}&key=43SBZ-IQW6I-627GS-5ILWQ-FGL37-XNFYX&referer=frontend-h5`
                  }
                  e.stopPropagation();
                }} >获取位置</button>
            }
          </div>
        </Cell>
        <Cell>
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", alignItems: "center" }}>
            <span style={{ color: "red" }}>*</span>
            鱼塘名称
          </span>
          <Input disabled={!location} maxlength={20} defaultValue={formValue?.name ?? ""} change={(val) => changes(val, "name")} />
        </Cell>
        <Cell >
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", alignItems: "center" }}>
            <span style={{ color: "red", opacity: 0 }}>*</span>
            鱼塘详细地址
          </span>
          <Input maxlength={20} disabled={!location} defaultValue={formValue?.address ?? ""} change={(val) => changes(val, "address")} />
        </Cell>

        <Form.Item
          label="营业开始时间"
          className="Pointrequird"
          name="rate"
          rules={[
            {
              validator: (rule, value) => {
                return !!desc2;
              },
              message: "当前字段错误",
            },
          ]}
        >
          <Cell
            desc={desc2}
            className="torish"
            onClick={() => operation("time", "setDesc2")}
          />
        </Form.Item>
        <Form.Item
          className="Pointrequird"
          label="营业结束时间"
          name="rate"
          rules={[
            {
              validator: (rule, value) => {
                return !!desc3;
              },
              message: "当前字段错误",
            },
          ]}
        >
          <Cell
            desc={desc3}
            className="torish"
            onClick={() => operation("time", "setDesc3")}
          />
        </Form.Item>

        <Cell >
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", alignItems: "center" }}>
            <span style={{ color: "red" }}>*</span>
            联系方式
          </span>
          <Input disabled={!location} type={"number"} maxlength={11} defaultValue={formValue?.contact ?? ""} change={(val) => changes(val, "contact")} />
        </Cell>

        <Cell >
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", alignItems: "center" }}>
            <span style={{ color: "red" }}>*</span>
            钓位数
          </span>
          <Input disabled={!location} maxlength={4} type={"number"} defaultValue={formValue?.seatnum ?? ""} change={(val) => changes(val, "seatnum")} />
        </Cell>

        <Cell title={"钓场类型"} style={{ display: "block" }}>
          <span style={{ width: "80px", paddingLeft: "20px" }}>
            <span style={{ color: "red" }}>*</span>
            钓场类型
          </span>
          <Checkbox.Group
            checkedValue={formValue.fishpondtype}
            onChange={(value) => onChangeSetFormValue("fishpondtype", value)}
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "28px", marginTop: "0.3rem" }}
          >
            <Checkbox label="0" style={{ marginRight: "10px" }}>
              黑坑
            </Checkbox>
            <Checkbox label="1" style={{ marginRight: "10px" }}>
              路亚
            </Checkbox>
            <Checkbox label="2" style={{ marginRight: "10px" }}>
              水库
            </Checkbox>
            {/* <Checkbox label="3">
              野钓
            </Checkbox> */}
          </Checkbox.Group>
        </Cell>
        <br />
        <h3 style={{ padding: "0 20px" }}>收费标准</h3>
        <Form.Item
          className="Pointrequird"
          label="开始时间"
          rules={[
            {
              validator: (rule, value) => {
                return !!desc4;
              },
              message: "当前字段必填",
            },
          ]}
        >
          <Cell
            desc={desc4}
            className="torish"
            onClick={() => operation("time", "setDesc4")}
          />
        </Form.Item>
        <Form.Item
          label="结束时间"
          className="Pointrequird"
          rules={[
            {
              validator: (rule, value) => {
                return !!desc5;
              },
              message: "当前字段错误",
            },
          ]}
        >
          <Cell
            desc={desc5}
            className="torish"
            onClick={() => operation("time", "setDesc5")}
          />
        </Form.Item>

        <Cell style={{ display: "block" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "80px", paddingRight: "24px", textAlign: "right" }}>
              <span style={{ color: "red" }}>*</span>
              计费方式
            </span>
            <Radio.RadioGroup value={"1"}>
              <Radio value="1" >小时（h）</Radio>
            </Radio.RadioGroup>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: "0.3rem", paddingLeft: "100px" }}>
            <Input maxlength={4} defaultValue={formValue?.price ?? ""} style={{ width: "50px", padding: 0, textAlign: "center !important" }} name="text" placeholder="0" change={(val) => setFormValue({ ...formValue, price: val })} />
            <span>元 </span>
            <span style={{ margin: "0 10px" }}> / </span>
            <Input maxlength={2} defaultValue={formValue?.hour ?? ""} style={{ width: "50px", padding: 0, textAlign: "center !important" }} name="text" placeholder="1" change={(val) => setFormValue({ ...formValue, hour: val })} />
            <span>小时</span>
          </div>
        </Cell>

        <Cell style={{ display: "block" }} >
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", paddingTop: "10px" }}>
            <span style={{ color: "red" }}>*</span>
            活动说明
          </span>
          <TextArea limitshow placeholder="请输入..." rows="4" maxlength={200} autosize defaultValue={formValue?.content ?? ""} onChange={(val) => changes(val, "content")} />
        </Cell>
        <Cell style={{ display: "block" }} >
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", paddingTop: "10px" }}>
            回鱼收费标准
          </span>
          <TextArea limitshow placeholder="请输入..." rows="4" maxlength={200} autosize defaultValue={formValue?.buyfish ?? ""} onChange={(val) => changes(val, "buyfish")} />
        </Cell>

        <br />
        <h3 style={{ padding: "0 20px" }}>钓场规则</h3>

        <Cell style={{ display: "block" }} >
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", paddingTop: "10px" }}>
            <span style={{ color: "red" }}>*</span>
            钓场规则
          </span>
          <TextArea limitshow placeholder="例如：1、不能使用路亚，不能用爆炸钩..." rows="4" maxlength={200} autosize defaultValue={formValue?.rule ?? ""} onChange={(val) => changes(val, "rule")} />
        </Cell>

        <br />
        <h3 style={{ padding: "0 20px" }}>鱼类</h3>

        <Cell style={{ display: "block" }} >
          <span style={{ width: "100px", paddingLeft: "20px", display: "flex", paddingTop: "10px" }}>
            <span style={{ color: "red" }}>*</span>
            鱼类
          </span>
          <TextArea limitshow placeholder="例如：混养、草鱼、青鱼等" rows="2" maxlength={50} autosize defaultValue={formValue?.fishs ?? ""} onChange={(val) => changes(val, "fishs")} />
        </Cell>

        <br />
        <h3 style={{ padding: "0 20px" }}> <span style={{ color: "red" }}>*</span>鱼塘相册(请上传4张实景图)</h3>
        {
          !loading && (
            <Form.Item>
              <Uploader defaultUrl={formValue.urls} fileListCallBack={fileListCallBack} loading={loading}></Uploader>
            </Form.Item>
          )
        }
        <br />
        <h3 style={{ padding: "0 20px" }}> <span style={{ color: "red" }}>*</span>鱼塘标签（不超过3个）</h3>
        <Form.Item label="">
          <div>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
              {
                formValue.fishpondLabel.map((item, index) => {
                  return (
                    <span style={{ padding: "5px 10px", color: "#333", borderRadius: "8px", display: "flex", alignItems: "center", border: "1px solid #eee", marginRight: "10px", marginBottom: "10px" }} key={index}>
                      {item}
                      <Icon onClick={() => {
                        let newValue = formValue.fishpondLabel.filter(k => k !== item);
                        setFormValue({ ...formValue, fishpondLabel: newValue })
                      }} style={{ marginLeft: "10px", fontSize: "15px" }} name="close-little"></Icon>
                    </span>
                  )
                })
              }
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input maxlength={20} placeholder="请输入" change={(e) => setInputValue({ ...inputValue, fishpondLabel: e })} style={{ border: "1px solid #eee", borderRadius: "6px", padding: "8px 15px" }} />
              <button
                type="button"
                style={{
                  width: "80px", border: "none", height: "40px", color: "#fff",
                  background: "linear-gradient(90deg, #57A1FF 0%, #2C68FF 100%)"
                }}

                onClick={(e) => {
                  e.stopPropagation();
                  onChangeSetFormValue("fishpondLabel", e)
                }} >添加</button>
            </div>
          </div>
        </Form.Item>
        <br />
        <Cell style={{ padding: "10px 20px", display: "flex", alignItems: "center" }}>
          <Checkbox textPosition="right" className="settlementAgreement" checked={formValue.settlementAgreement} onChange={(e) => onChangeSetFormValue("settlementAgreement", e)} />
          <span className="settlementAgreementText" onClick={() => setShowBottomRound(true)}>入驻协议</span>
        </Cell>
        <div style={{ margin: "20px 20px 6px" }}>
          <input
            style={{
              width: "100%", border: "none", height: "40px", color: "#fff",
              background: "linear-gradient(90deg, #57A1FF 0%, #2C68FF 100%)", borderRadius: "0.45rem"
            }}
            disabled={CreateAxios.loading} type="submit" className="m-r-10" value={"保存"} />
        </div>
        <div style={{ padding: "0 20px" }}>
          <div style={{ margin: "0 0 30px", display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ fontSize: "12px", textAlign: "right", color: "#999" }}>保存后请在鱼塘列表发布公开上线</span>
          </div>
        </div>
      </Form>
      <Popup closeable visible={showBottomRound} style={{ height: '90%' }} position="bottom" round onClose={() => { setShowBottomRound(false) }} >
        <SettlementAgreementComponent callbackClick={() => setShowBottomRound(false)} />

      </Popup>
    </div>
  );
};
export default withRouter(EditFistPollComponent);