import http from '../axios/index';
export const getAnnouncementAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("get", '/basic/announcement/getAnnouncement',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
