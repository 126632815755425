import React, { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Cell, Button, Notify } from '@nutui/nutui-react'
import "./style.css";
import { withRouter, Redirect } from "react-router-dom";
import { useRequest } from 'ahooks';
import { sentSmsAxios, loginAxios, wxLoginAxios, getWxInfoAxios } from "@/commons/fetch/access";
import { phoneVerity, getUrlParam } from "@/commons/utils/helps";
import LOGO from "@/static/login/logo.png";
import { Radio } from '@nutui/nutui-react';
import WX_LOGIN_ICON from "@/static/image/wxlogin.png";
const LoginComponent = (props) => {
    const [radioVal, setRadioVal] = useState(false)
    const [formValue, setFormValue] = useState({
        phone: "",
        sms: ""
    });
    const [isRedirect, setIsRedirect] = useState(false);

    const loginError = () => {
        setTimeout(() => {
            window.location.href = window.location.origin + window.location.pathname;
        }, 2000);
    }
    useEffect(() => {
        localStorage.clear()
    }, [])
    const getWxLogin = async (code) => {
        try {
            let res = await wxLoginAxios({ code });
            if (res?.success) {
                if (res?.data?.auth) {
                    window.Messages.open("登陆成功", "success");
                    localStorage.setItem("token", res?.data?.auth);
                    setIsRedirect(true);
                    return
                }
                window.Messages.open("获取信息失败!", "warn");
                loginError();
                return
            }
            window.Messages.open(res.msg, "warn");
            loginError();
        } catch (error) {
            window.Messages.open("登录失败！", "warn");
            loginError();
        }
    }
    useEffect(() => {
        if (getUrlParam("code")) {
            getWxLogin(getUrlParam("code"))
        }
    }, [])

    if (isRedirect) {
        return <Redirect to="/console" />
    }
    if (getUrlParam("code")) {
        return <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            登录中...
        </div>
    }
    return (
        <div className="loginBox">
            <div className="loginBoxInner">
                <div className="backgroundImage"></div>
                <h3 className="login_h3" >
                    <img src={LOGO} alt="" style={{ width: "5.07rem" }} />
                </h3>
                {/* <div className="login_content">
                    <Input
                        label={<img src={LOGIN_ICON_USER} alt="" style={{ width: "0.4rem" }} />}
                        placeholder="请输入手机号"
                        defaultValue={formValue.phone}
                        clearable
                        center
                        change={(e) => setFormValue({ ...formValue, phone: e })}
                        slotButton={<Button
                            disabled={btn !== "发送验证码"}
                            size="small"
                            onClick={sentCodeFun}
                            type="info"
                        >{btn}</Button>}
                    />
                    <Input
                        label={<img src={LOGIN_ICON_PASSWORD} alt="" style={{ width: "0.4rem" }} />}
                        placeholder="请输入验证码"
                        defaultValue={formValue.sms}
                        clearable
                        change={(e) => setFormValue({ ...formValue, sms: e })}
                        center
                    />
                    <Button
                        disabled={!(Object.values(formValue).filter(k => k && k.length > 0).length === 2)}
                        className="loginBtn"
                        shape="square"
                        loading={submitRequest.loading}
                        onClick={formSubmit}
                        type="info">登录/注册</Button>
                </div> */}
                <div className="btn_box">
                    <div className="deviceBox">
                        <Radio.RadioGroup value={radioVal} onChange={(e) => setRadioVal(e)} className="RadioGroup">
                            <Radio value="1" >
                                <span className="test1">登陆即视为同意
                                    <span
                                        onClick={() => window.location.href = "https://epicfish.cn/userServiceAgreement.html"}
                                        className="test2">
                                        《用户协议》
                                    </span>
                                    和
                                    <span className="test2"
                                        onClick={() => window.location.href = "https://epicfish.cn/privacyPolicy.html"}
                                    >
                                        《隐私协议》
                                    </span>
                                </span>
                            </Radio>
                        </Radio.RadioGroup>
                    </div>
                    <div className="wxLogin">
                        <div className="btn_login"
                            onClick={() => {
                                if (radioVal !== "1") {
                                    window.Messages.open("请仔细阅读协议!", "warn");
                                    return;
                                }
                                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5747454840b12947&redirect_uri=${window.location.origin}${window.location.pathname}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
                                // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5747454840b12947&redirect_uri=https://h5.epicfish.cn/h5/logins&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
                            }}
                        >
                            微信授权一键登陆
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(withRouter(LoginComponent));