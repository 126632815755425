import http from '../axios/index';
//商家显示所有订单
export const forderMerchantListAxios = (params) => {
    return new Promise((resolve, reject) => {
        // http("post", '/forder/merchant/listbystatus',params).then(res => {
        http("post", '/forder/merchant/listbystatus',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//商家显示所有订单
export const forderMerchantCancelOriderAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/forder/merchant/cancel',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//商家核销订单
export const verifyOriderAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/forder/merchant/verify',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}