import React, { memo, useEffect } from "react";
import Banner from "./banner";
import { withRouter } from "react-router-dom";
import Amount from "./amount";
import MenuComponent from "./menu";
import { userDetailAxios, wxScanAxios } from "@/commons/fetch/access";
import { useRequest, useDebounceEffect } from 'ahooks';
import { PullToRefresh } from '@nutui/nutui-react';
import { useState } from "react";

const MeComponent = (props) => {
    const { error, runAsync } = useRequest(userDetailAxios, {
        manual: true
    });
    const [data,setData] = useState({});
    const wxFun = useRequest(wxScanAxios, {
        manual: true
    });

    const getData = async() =>{
        try {
            let res = await runAsync();
            if(res.success && res.data){
                localStorage.setItem("userinfo", JSON.stringify(res.data));
                setData(res);
            }
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        getData()
    },[])
 
    return (
        <div>
            <PullToRefresh onRefresh={getData}>
                <Banner {...props} data={data?.data} />
                <Amount {...props} data={data?.data} callback={() => runAsync()} />
                <MenuComponent {...props} data={data?.data} />
            </PullToRefresh>
        </div>
    )
}

export default memo(withRouter(MeComponent));