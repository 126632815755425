
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import LoginComponent from "./views/access/Login";
import RegisterComponent from "./views/access/Register";
import IndexConponent from "./layouts/index";
import ForgetPasswordComponent from "./views/access/ForgetPassword";
import Messages from "@/components/Message";
import OrderComponent from "./views/orders/index";
import { useEffect } from "react";

// import Vconsole from 'vconsole';

function App() {
  // const vconsole = new Vconsole()
  const resizeListener = () => {
    // 定义设计图的尺寸 3840
    let designSize = 750;
    // 获取 html 元素
    let html = document.documentElement;
    // 定义窗口的宽度
    let clientW = html.clientWidth;
    let clienH = html.clientHight;
    // html 的fontsize 大小
    let htmlRem = (clientW * 100) / designSize;
    html.style.fontSize = htmlRem + 'px';
  }
  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    resizeListener();
  })
  return (
    <BrowserRouter basename="/h5">
        <Messages />
        <Switch>
          <Route exact path={"/login"} component={LoginComponent} />
          <Route exact path={"/register"} component={RegisterComponent} />
          <Route exact path={"/forget-password"} component={ForgetPasswordComponent} />
          <Route
            exact
            path="/console"
            render={() => <Redirect to="/console/home" />}
          />
          <Route path="/console" component={IndexConponent} />
          <Route exact path={"/order"} component={OrderComponent} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;
