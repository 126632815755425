import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from '@nutui/nutui-react';
import '@nutui/nutui-react/dist/style.css'
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
const darkTheme = {
  nutuiBrandColor: '#2C68FF',
  nutuiBrandColorStart: '#57A1FF',
  nutuiBrandColorEnd: '#2C68FF',
}
root.render(
  // <React.StrictMode>
  <ConfigProvider theme={darkTheme}>
    <App />
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
