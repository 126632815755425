import React, { memo } from "react";
import { Button } from "@nutui/nutui-react";

import "../styles/text.scss";
const SettlementAgreement = ({callbackClick}) => {
    return (
        <div className="SettlementAgreement">
            <h3 style={{ padding: "0.2rem 0", textAlign: 'center' }}> 《电商平台服务协议》与交易规则  </h3>
            <div style={{ padding: "0.2rem 0.3rem 1rem" }}>
                <h4>一、定义</h4>
                <p>
                    史诗鱼平台：指基于微信端的“史诗鱼”小程序。
                </p>
                <p>平台服务：豆豆龙基于微信平台（包括未来技术发展出现的新的服务形态）向您提供的各项服务。</p>
                <p>豆豆龙：指平台服务的最终提供者南京豆豆龙电子商务有限公司。</p>
                <p>史诗鱼平台规则：包括在所有史诗鱼平台内已经发布及后续发布的全部规则、解读、公告、服务、产品流程说明等内容。</p>
                <p>同一用户：使用同一身份认证信息或经史诗鱼小程序排查认定多个史诗鱼商户账户的实际控制人为同一人的，均视为同一用户。</p>
                <h4>二、签约主体</h4>
                <p>本协议由您与豆豆龙共同缔结，本协议对您与豆豆龙均具有合同效力，本协议中史诗鱼平台的权利与义务最终由豆豆龙享有与承担。</p>
                <h4>三、账户注册与使用</h4>
                <h6>1、 用户资格</h6>
                <p>您确认，在您开始注册程序使用史诗鱼平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</p>
                <p>此外，您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用史诗鱼平台服务。</p>
                <h6>2、 账户说明</h6>
                <p>【账户获得】当您按照页面提示注册、阅读并同意本协议且完成全部注册程序后，您成为史诗鱼平台用户。</p>
                <p>【账户使用】您有权使用您的手机号码或微信（以下简称“账户名称”）登陆史诗鱼平台。</p>
                <p>由于您的史诗鱼账户关联您的个人信息及史诗鱼平台商业信息，您的史诗鱼账户仅限您本人使用。未经史诗鱼平台同意，您直接或间接授权第三方使用您史诗鱼账户或获取您账户项下信息的行为无效。如史诗鱼平台判断您史诗鱼账户的使用可能危及您的账户安全及/或史诗鱼平台信息安全的，史诗鱼平台可拒绝提供相应服务或终止本协议。</p>
                <p>【账户转让】由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经史诗鱼平台同意，并符合史诗鱼平台规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则史诗鱼平台有权追究您的违约责任，且由此产生的一切责任均由您承担。</p>
                <p>【实名认证】作为史诗鱼平台经营商户或使用用户，为使您更好地使用史诗鱼平台的各项服务，保障您的账户安全，史诗鱼平台可要求您按第三方支付公司要求及我国法律规定完成实名认证。</p>
                <h6>3、 注册信息管理</h6>
                <p> <strong>1) 真实合法</strong></p>
                <p>【信息真实】在使用史诗鱼平台服务时，您应当根据商户或用户的身份要去，按史诗鱼平台页面的提示准确完整地提供商户或用户信息（包括但不限于您的账户名、联系电话、联系地址等），以便史诗鱼平台或其他用户与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。</p>
                <p>【商户名称的合法性】您设置的商户名称不得违反国家法律法规，否则史诗鱼平台可回收您的商户名称。</p>
                <h6>4、 商户安全规范</h6>
                <p>【商户安全保管义务】您的商户为您自行设置并由您保管，史诗鱼平台任何时候均不会主动要求您提供您的商户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开史诗鱼平台。
                    商户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，史诗鱼平台并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</p>
                <p>【商户行为责任自负】除史诗鱼平台存在过错外，您应对您商户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。</p>
                <p>【日常维护须知】如发现任何未经授权使用您账户登录史诗鱼平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知史诗鱼平台，并授权史诗鱼平台将该信息同步给第三方支付平台。您理解史诗鱼平台对您的任何请求采取行动均需要合理时间，且史诗鱼平台应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除史诗鱼平台存在法定过错外，史诗鱼平台不承担责任。</p>
                <h4>四、史诗鱼平台服务及规范</h4>
                <p>【服务概况】您有权在史诗鱼平台上享受店铺管理、商品及/或服务的销售与推广、商品及/或服务的购买与评价、交易争议处理等服务。史诗鱼提供的服务内容众多，具体您可登录史诗鱼平台浏览。</p>
                <h6>1、 店铺管理</h6>
                <p>【店铺创建】通过在史诗鱼平台创建店铺，您可发布钓场服务信息并与其他用户达成交易。</p>
                <p>基于史诗鱼平台管理需要，您理解并认可，同一用户在史诗鱼平台仅能开设一家店铺，史诗鱼平台可关闭您在史诗鱼平台同时开设的其他店铺。</p>
                <p>【店铺转让】由于店铺与账户的不可分性，店铺转让实质为店铺经营者账户的转让，店铺转让的相关要求与限制请适用本协议 (三、账户注册与使用 - 2、账户说明)条账户转让条款。</p>
                <p>【店铺关停】如您通过下架全部商品或服务短暂关停您的店铺，您应当对您店铺关停前已达成的交易继续承担发货、退换货及质保维修、维权投诉处理等交易保障责任。</p>
                <p>依据上述约定关停店铺均不会影响您已经累积的信用。</p>
                <h6>2、商品及/或服务的销售与推广</h6>
                <p>【商品及/或服务信息发布】通过史诗鱼平台提供的服务，您有权通过文字、图片、视频、音频等形式在史诗鱼平台上发布商品及/或服务信息、招揽和物色交易对象、达成交易。</p>
                <p>【禁止销售范围】您应当确保您对您在史诗鱼平台上发布的商品及/或服务享有相应的权利，您不得在史诗鱼平台上销售以下商品及/或提供以下服务：</p>
                <div>
                    <p>（一）国家禁止或限制的；</p>
                    <p>（二）侵犯他人知识产权或其它合法权益的；</p>
                    <p>（三）史诗鱼平台规则、公告、通知或各平台与您单独签署的协议中已明确说明不适合在史诗鱼平台上销售及/或提供的。</p>
                    <p>【交易秩序保障】您应当遵守诚实信用原则，确保您所发布的商品及/或服务信息真实、与您实际所销售的商品及/或提供的服务相符，并在交易过程中切实履行您的交易承诺。</p>
                    <p>您应当维护史诗鱼平台市场良性竞争秩序，不得贬低、诋毁竞争对手，不得干扰史诗鱼平台上进行的任何交易、活动，不得以任何不正当方式提升或试图提升自身的信用度，不得以任何方式干扰或试图干扰史诗鱼平台的正常运作。</p>
                    <p>【交易支付】交易当事人可以约定采用电子支付方式支付价款。电子支付服务提供者为交易提供电子支付服务，应当遵守国家规定，告知用户电子支付服务的功能、使用方法、注意事项、相关风险和收费标准等事项，不得附加不合理交易条件。电子支付服务提供者应当确保电子支付指令的完整性、一致性、可跟踪稽核和不可篡改。电子支付服务提供者应当向用户免费提供对账服务以及最近三年的交易记录。</p>
                    <p>【支付授权】用户应当妥善保管交易密码、电子签名数据等安全工具。用户发现安全工具遗失、被盗用或者未经授权的支付的，应当及时通知史诗鱼平台和电子支付服务提供者。未经授权的支付造成的损失，由电子支付服务提供者承担；电子支付服务提供者能够证明未经授权的支付是因用户的过错造成的，不承担责任。电子支付服务提供者发现支付指令未经授权，或者收到用户支付指令未经授权的通知时，应当立即采取措施防止损失扩大。电子支付服务提供者未及时采取措施导致损失扩大的，对损失扩大部分承担责任。</p>
                    <p>【促销及推广】您有权自行决定商品及/或服务的促销及推广方式，史诗鱼平台亦为您提供了形式丰富的促销推广工具。您的促销推广行为应当符合国家相关法律法规及史诗鱼平台的要求。</p>
                    <p>【依法纳税】依法纳税是每一个公民、企业应尽的义务，您应对销售额/营业额超过法定免征额部分及时、足额地向税务主管机关申报纳税。</p>
                </div>
                <h6>3、商品及/或服务的购买与评价</h6>
                <p>【商品及/或服务的购买】当您在史诗鱼平台购买商品及/或服务时，请您务必仔细确认所购商品或服务的的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。</p>
                <p>您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱史诗鱼平台正常交易秩序的行为。基于维护史诗鱼平台交易秩序及交易安全的需要，史诗鱼平台发现上述情形时可主动执行关闭相关交易订单等操作。</p>
                <p>【评价】您有权在史诗鱼平台提供的评价系统中对与您达成交易的其他用户商品及/或服务进行评价。您应当理解，您在史诗鱼平台的评价信息是公开的，如您不愿意在评价信息中向公众披露您的身份信息，您有权选择通过匿名形式发表评价内容。</p>
                <p>您的所有评价行为应遵守史诗鱼平台规则的相关规定，评价内容应当客观真实，不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息；您不应以不正当方式帮助他人提升信用或利用评价权利对其他用户实施威胁、敲诈勒索。史诗鱼平台可按照史诗鱼平台规则的相关规定对您实施上述行为所产生的评价信息进行删除或屏蔽。</p>
                <h6>4、费用</h6>
                <p>史诗鱼平台向您提供的服务付出了大量的成本，史诗鱼平台根据明示的收费业务进行收费。如未来史诗鱼平台向您收取合理费用，史诗鱼平台会采取合理途径并以足够合理的期限提前通过法定程序并以本协议第八条约定的方式通知您，确保您有充分选择的权利。</p>
                <h6>5、责任限制</h6>
                <p>【不可抗力及第三方原因】史诗鱼平台依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，史诗鱼平台并不承担相应的违约责任：</p>
                <div>
                    <p>（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</p>
                    <p>（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</p>
                    <p>（三）在史诗鱼平台已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</p>
                </div>
                <p>【调处决定】您理解并同意，在争议调处服务中，史诗鱼平台的客服并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意或重大过失外，调处方对争议调处决定免责。</p>
                <h4>五、 用户信息的保护及授权</h4>
                <p>个人信息的保护</p>
                <p>史诗鱼平台非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用史诗鱼平台提供的服务时，您同意史诗鱼平台按照法律、行政法规有关个人信息的规定收集、存储、使用、披露和保护您的个人信息。</p>
                <p>【授权使用】对于您提供、发布及在使用史诗鱼平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，在法律规定的保护期限内您免费授予豆豆龙及其关联公司、第三方支付平台获得全球排他的许可使用权利及再授权给其他第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。您同意豆豆龙及其关联公司、第三方支付平台存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</p>
                <p>【查询、更正、删除】平台将提供明示用户信息查询、更正、删除以及用户注销的方式、程序，不得对用户信息查询、更正、删除以及用户注销设置不合理条件。平台收到用户信息查询或者更正、删除的申请的，应当在核实身份后及时提供查询或者更正、删除用户信息。用户注销的，平台应当立即删除该用户的信息；依照法律、行政法规的规定或者双方约定保存的，依照其规定。</p>
                <h4>六、用户的违约及处理</h4>
                <h6>1、违约认定</h6>
                <p>发生如下情形之一的，视为您违约：</p>
                <div>
                    <p>（一）使用史诗鱼平台服务时违反有关法律法规规定的；</p>
                    <p>（二）违反本协议约定的。</p>
                </div>
                <p>为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，史诗鱼平台可在史诗鱼平台规则中约定违约认定的程序和标准。如：史诗鱼可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</p>
                <h6>2、违约处理措施</h6>
                <p>【信息处理】您在史诗鱼平台上发布的信息构成违约的，史诗鱼平台可根据相应规则立即对相应信息进行删除、屏蔽处理或对您的商品进行下架、监管。</p>
                <p>【行为限制】您在史诗鱼平台上实施的行为，或虽未在史诗鱼平台上实施但对史诗鱼平台及其用户产生影响的行为构成违约的，史诗鱼平台可依据相应规则对您执行账户进行限时下架、限制参加营销活动、中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，史诗鱼平台可查封您的商户，终止向您提供服务。</p>
                <p>【处理结果公示】史诗鱼平台可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在史诗鱼平台上予以公示。</p>
                <h6>3、赔偿责任</h6>
                <p>如因商户的行为或服务使得第三人遭受损失或您怠于履行调处决定、豆豆龙及/或其关联公司出于社会公共利益保护或消费者权益保护目的，您应当返还该部分费用并赔偿因此造成豆豆龙的全部损失。同时因为消费者到店服务造成给的人身或/及财产损失，与豆豆龙无关，同时商户应赔偿因此造成豆豆龙的全部损失。</p>
                <h4>七、 协议的变更</h4>
                <p>史诗鱼平台可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第八条约定的方式通知您。</p>
                <p>如您不同意变更事项，您有权于变更事项确定的生效日前联系史诗鱼平台反馈意见。如反馈意见得以采纳，史诗鱼平台将酌情调整变更事项。</p>
                <p>如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用史诗鱼平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用史诗鱼平台服务，则视为您同意已生效的变更事项。</p>
                <h4>八、通知</h4>
                <h6>1、有效联系方式</h6>
                <p>您在注册成为史诗鱼平台用户，并接受史诗鱼平台服务时，您应该向史诗鱼平台提供真实有效的联系方式（包括但不限于您的电话、邮箱、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</p>
                <p>史诗鱼平台将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</p>
                <p>您有权通过您注册时填写的手机号码或者微信号获取您感兴趣的商品或服务的广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过史诗鱼平台提供的相应的退订功能进行退订。</p>
                <h6>2、通知的送达</h6>
                <p>史诗鱼平台通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在史诗鱼平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
                    您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</p>
                <p>您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</p>
                <p>你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</p>
                <h4>九、 协议的终止</h4>
                <h6>1、终止的情形</h6>
                <p>【用户发起的终止】您有权通过以下任一方式终止本协议：</p>
                <div>
                    <p>（一）在满足史诗鱼平台公示的账户注销条件时您通过网站自助服务注销您的账户的；</p>
                    <p>（二）变更事项生效前您停止使用并明示不愿接受变更事项的；</p>
                    <p>（三）您明示不愿继续使用史诗鱼平台服务，且符合史诗鱼平台终止条件的。</p>
                </div>
                <p>【史诗鱼平台发起的终止】出现以下情况时，史诗鱼平台可以本协议第八条的所列的方式通知您终止本协议：</p>
                <div>
                    <p>（一）您违反本协议约定，史诗鱼平台依据违约条款终止本协议的；</p>
                    <p>（二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，史诗鱼平台依据史诗鱼平台规则对您的账户予以查封的；</p>
                    <p>（三）除上述情形外，因您多次违反史诗鱼平台规则相关规定且情节严重，史诗鱼平台依据史诗鱼平台规则对您的账户予以查封的；</p>
                    <p>（四）您的账户被史诗鱼平台依据本协议回收的；</p>
                    <p>（五）其它应当终止服务的情况。</p>
                </div>
                <h6>2、协议终止后的处理</h6>
                <p>【用户信息披露】本协议终止后，除法律有明确规定外，史诗鱼平台无义务向您或您指定的第三方披露您账户中的任何信息。</p>
                <p>【史诗鱼平台权利】本协议终止后，史诗鱼平台仍享有下列权利：</p>
                <div>
                    <p>（一）继续保存您留存于史诗鱼平台的本协议第五条所列的各类非个人信息；</p>
                    <p>（二）对于您过往的违约行为，史诗鱼平台仍可依据本协议向您追究违约责任。</p>
                </div>
                <p>【交易处理】本协议终止后，对于您在本协议存续期间产生的交易订单，史诗鱼平台可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</p>
                <h4>十、 法律适用、管辖与其他</h4>
                <p>【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</p>
                <p>【管辖】您因使用史诗鱼平台服务所产生及与史诗鱼平台服务有关的争议，由史诗鱼平台与您协商解决。协商不成时，任何一方均可向豆豆龙所在地人民法院提起诉讼。</p>
                <p>【可分性】本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
                <div style={{ display: "flex", marginTop: "10px", justifyContent: "center" }}>
                    <Button onClick={callbackClick} type="info">我已了解</Button>
                </div>
            </div>
        </div>
    )
}

export default memo(SettlementAgreement)