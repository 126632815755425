import React, { memo, useEffect, useState } from "react";
import { Form, Uploader, Input, Cell, Button } from '@nutui/nutui-react';
import { updateCardInfo, cardNoValidAxios } from "@/commons/fetch/access";
import { useRequest } from 'ahooks';
import { CardInfo, CardType, checkTelephone } from "@/commons/utils/helps"
import { withRouter } from "react-router-dom";
import "./BindBankCardStyles.css";
import { getUrlParam } from "@/commons/utils/helps";
const OSS = require('ali-oss');

const uploadUrl = ''
const client = new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAI5tFpjUXDkwWj8oPvzpLJ',
    accessKeySecret: 'MF827FqQq00VjWHdFtIJw8ZpvxyUAD',
    bucket: 'epicfish-media'
});
const BindBankCardComponent = (props) => {
    const card = getUrlParam("bankno");
    const RealName = getUrlParam("realname");
    const Phone = getUrlParam("phone");
    const [defaultFileList, setDefaultFileList] = useState(getUrlParam("bankurl") ? { url: getUrlParam("bankurl") } : {});
    const [carkDetail, setCarkDetail] = useState(null);
    const [message, setMessage] = useState(null);
    const [phoneFormat, setPhoneFormat] = useState(true);
    const { loading, run } = useRequest(updateCardInfo, {
        manual: true,
        onSuccess: (result, params) => {
            console.log(result, params)
            if (result.success && result?.status_code === 0) {
                window.Messages.open("绑定银行卡成功！如有疑问请联系客服人员", "success");
                props.history.goBack();
            } else {
                window.Messages.open(result?.msg ?? "绑定失败！，请联系客服", "error");
            }
        },
        onError: (error) => {
            window.Messages.open(error?.msg ?? "绑定失败！，请联系客服", "error");
        },
    });
    const onBeforeXhrUpload = async (file, option) => {
        let path = "";
        // if (localStorage.getItem("userinfo") && JSON.parse(localStorage.getItem("userinfo"))?.Userid) {
        //     path = JSON.parse(localStorage.getItem("userinfo"))?.Userid
        // }
        path = path + "/card/" + file[0]?.name
        try {
            const result = await client.put(
                path,
                file[0]
                //{headers}
            );
            setDefaultFileList({
                name: result?.name,
                url: result?.url,
                status: 'success',
                message: '上传成功',
                type: 'image',
                uid: result?.url,
            })
            return true;
        } catch (e) {
            console.log(e);
        }
    }
    const submitSucceed = async (obj) => {
        if (!checkTelephone(obj.phone)) {
            setPhoneFormat(false);
            window.Messages.open("请输入正确的手机号", "primary");
            return;
        }
        if (!obj?.card && card) {
            obj['card'] = card;
        }
        if (!obj?.username && RealName) {
            obj['username'] = RealName;
        }
        if (obj?.card && obj?.username && defaultFileList?.url) {
            if (obj?.card) {
                let res = await cardNoValidAxios({ cardNo: obj?.card });
                if (res && res?.validated) {
                    run({
                        "bankno": obj?.card,
                        "bankurl": defaultFileList?.url,
                        "realname": obj?.username,
                        name: JSON.parse(localStorage.getItem("userinfo"))?.Phone,
                        "phone": obj?.phone
                    })
                } else {
                    setCarkDetail(null);
                    setMessage("请输入正确的银行卡号");
                    window.Messages.open("请输入正确的银行卡号", "primary");
                    return;
                }
            }

        } else {
            window.Messages.open("请至少选择一种上传银行卡方式", "primary");
        }
    }
    const blur = async (obj) => {
        if (obj.length > 10) {
            let res = await cardNoValidAxios({ cardNo: obj });
            if (res && res?.validated) {
                setCarkDetail(res)
                setMessage(null)
            } else {
                setCarkDetail(null);
                setMessage("请输入正确的银行卡号");
                window.Messages.open("请输入正确的银行卡号", "primary");
                setMessage("请输入正确的银行卡号")
                return;
            }
            return false;
        }
        window.Messages.open("请输入正确的银行卡号", "primary");
    }
    useEffect(() => {
        if (card) {
            blur(card)
        }
    }, [])
    return (
        <div>
            <Form
                labelPosition="Top"
                onFinish={(obj) => submitSucceed(obj)}

            >
                <Form.Item label='银行卡号' name="card" message={message}>
                    <Input
                        className="nut-input-text"
                        placeholder='请输入银行卡号'
                        type="text"
                        onBlur={blur}
                        defaultValue={card ?? ""}
                        onChange={() => {
                            setCarkDetail(null);
                            setMessage(null);
                        }}
                    />
                </Form.Item>
                {
                    carkDetail?.bank && <Form.Item label='银行卡信息' name="card3">
                        <p>{CardInfo(carkDetail?.bank)} / {CardType(carkDetail?.cardType)}</p>
                        <p></p>
                    </Form.Item>
                }
                <Form.Item label='上传银行卡号照片' name="card2">
                    <div className="cardBox">
                        {<Uploader
                            url={uploadUrl}
                            // onStart={onStart}
                            onBeforeUpload={onBeforeXhrUpload}
                            maximum={1}
                            accept="image/*"
                            capture

                        />}
                        {
                            defaultFileList && defaultFileList?.url && (
                                <img src={defaultFileList?.url} style={{ width: "100px", display: "block", margin: "0 auto" }} />
                            )
                        }
                    </div>
                </Form.Item>
                <Form.Item label='银行卡持有者姓名' name="username" style={{ width: "100%" }}>
                    <Input
                        className="nut-input-text"
                        placeholder='银行卡持有者姓名，防止汇款时出现错误'
                        type="text"
                        defaultValue={RealName ?? ""}
                    />
                </Form.Item>
                <Form.Item label='手机号' name="phone" style={{ width: "100%" }}>
                    <Input
                        type="number"
                        className="nut-input-text"
                        placeholder='请绑定手机号，防止汇款时出现错误'
                        defaultValue={Phone ?? ""}
                        change={(val) => {
                            if (!checkTelephone(val)) {
                                setPhoneFormat(false)
                            } else {
                                setPhoneFormat(true)
                            }
                        }}
                    />
                </Form.Item>
                {
                    !phoneFormat && <span style={{ color: "red", paddingLeft: "16px", fontSize: "12px" }}>手机号格式错误</span>
                }
                <Cell>
                    <input type="submit" value='提交' placeholder="提交" className="submit" />

                </Cell>
            </Form>
        </div>
    )
}
export default memo(withRouter(BindBankCardComponent));