import http from '../axios/index';
import axios from "axios";
//发送验证码
export const wxScanAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("get", '/fuser/wechat/scan',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//发送验证码
export const sentSmsAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("POST", '/fuser/sms/send',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//登录
export const loginAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("POST", '/fuser/sms/login',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//查看自己信息
export const userDetailAxios = () => {
    return new Promise((resolve, reject) => {
        http("get", 'fuser/merchant/detail').then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//更新银行卡信息
export const updateCardInfo = (params) => {
    return new Promise((resolve, reject) => {
        http("post", 'fuser/merchant/update',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//更新银行卡信息
export const cardNoValidAxios = (params) => {
    return new Promise((resolve, reject) => {
        axios.get("https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardBinCheck=true", {  params: params }).then((response) => {
            resolve(response?.data);
        })
            .catch((error) => {
                reject(error);
            });
    });
}
//获取微信信息
export const getWxInfoAxios = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(window.location.origin+`/wxuserinfo/sns/userinfo?access_token=${params.access_token}&openid=${params.openid}&lang=zh_CN`).then((response) => {
            resolve(response?.data);
        })
            .catch((error) => {
                reject(error);
            });
    });
}
//提现记录
export const withdrawListAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", 'fuser/merchant/withdraw/list',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}

// 商户提现 操作
export const merchantWithdrawAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", 'fuser/merchant/withdraw',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
// 微信登录
export const wxLoginAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", 'fuser/wechat/merchant/login',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}