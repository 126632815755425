export const backList = [
    "/console/explai",
    "/console/authentication",
    "/console/bindBankCard",
    "/console/withdrawalRecord",
    "/console/home-edit",
    "/console/qr",
    "/console/announcement"
]
export const mineQRCode = "/console/home";
export const headerTitle = () =>{
    switch (window.location.pathname) {
        case "/h5/console/order":
            return "我的订单";
        case "/h5/console/home":
            return "鱼塘首页";
        case "/h5/console/withdrawalRecord":
            return "提现列表";
        case "/h5/console/bindBankCard":
            return "绑定银行卡";
        case "/h5/console/explai":
            return "平台使用说明";
        case "/h5/console/home-edit":
            return "创建/编辑鱼塘";
        case "/h5/console/qr":
            return "扫一扫";
        case "/h5/console/success":
            return "订单核销";
        case "/h5/console/announcement":
            return "公告栏";
        default:
            break;
    }
};