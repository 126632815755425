import http from '../axios/index';
import {API_KEY} from "../utils/constants";
//商户创建鱼塘
export const merchantsCreatFishPonds = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/fishpond/merchant/create',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//商户更新鱼塘
export const merchantsUpdateFishPonds = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/fishpond/merchant/update',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}
//商户查看鱼塘列表
export const fishpondMerchantListAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/fishpond/merchant/list',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}

//商户鱼塘上架
export const fishPondsOnShelvesAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/fishpond/merchant/on',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}

//商户鱼塘下架
export const fishPondsOffShelvesAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/fishpond/merchant/off',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}

//商户鱼塘删除
export const fishPondsDeleteAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("post", '/fishpond/merchant/del',params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}

//获取 腾讯地图逆向解析
export const tenMapAxios = (params) => {
    return new Promise((resolve, reject) => {
        http("get", `https://apis.map.qq.com/ws/geocoder/v1?location=${params.a},${params.b}&get_poi=1&key=${API_KEY}`,params).then(res => {
            resolve(res);
        }, error => {
            reject(error)
        })
    })
}

