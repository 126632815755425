

import React from 'react';
import HomeComponent from "../views/homes";
import OrderComponent from "../views/orders";
import OrderIdSuccess from "../views/orders/OrderIdSuccess";
import MinesComponent from "../views/mines";
import ExplaiComponent from "../views/mines/page/Explai";
import SelectCity from "../views/citys";
import SelectCityComponent from "../views/citys/city/City";
import Authentication from "../views/mines/page/Authentication";
import BindBankCard from "../views/mines/page/BindBankCard";
import WithdrawalRecord from "../views/mines/page/WithdrawalRecord";
import Announcement from "../views/mines/page/Announcement";
import EditFishPool from "../views/homes/component/EditFishPool";
import QRComponent from "../views/homes/qr";
const routeList = [
    { id: 0, label: "鱼塘", icon: "home", link: "/console/home", component: HomeComponent, isHide: false },
    { id: "0-0", link: "/console/home-edit", component: EditFishPool, isHide: true },
    { id: "0-1", link: "/console/home-edit-city", component: SelectCity, isHide: true },
    { id: "0-2", link: "/console/home-edit-city-component", component: SelectCityComponent, isHide: true },
    { id: "0-3", link: "/console/qr", component: QRComponent, isHide: true },
    { id: 1, label: "订单", icon: "order", link: "/console/order", component: OrderComponent, isHide: false },
    { id: "1-0", link: "/console/success", component: OrderIdSuccess, isHide: true },
    { id: 2, label: "我的", icon: "my", link: "/console/mine", component: MinesComponent, isHide: false},
    { id: "2-0", link: "/console/explai", component: ExplaiComponent, isHide: true },
    { id: "2-1", link: "/console/authentication", component: Authentication, isHide: true },
    { id: "2-2", link: "/console/bindBankCard", component: BindBankCard, isHide: true },
    { id: "2-3", link: "/console/withdrawalRecord", component: WithdrawalRecord, isHide: true },
    { id: "2-4", link: "/console/announcement", component: Announcement, isHide: true },


    







];

export default routeList;