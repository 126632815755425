import React, { memo, useState, useEffect, useRef } from "react";
import { Cell, Infiniteloading, Empty, Price,PullToRefresh } from '@nutui/nutui-react';
import { InfiniteUlStyle, InfiniteLiStyle, CellInfiniteUlStyle, InfiniteLiItemStyle } from "@/commons/styles/WithdrawalRecordStyles";
import { withdrawListAxios } from "@/commons/fetch/access";
import { useRequest } from "ahooks";
import { withdrawalRecordStatus } from "../../../commons/utils/helps";
let onload = true;
const WithdrawalRecordComponent = () => {
    const [customHasMore, setCustomHasMore] = useState(true);
    const [total, setTotal] = useState(0);
    let page = useRef(1);
    let customList = useRef([]);

    const { data, loading, run, runAsync, refresh } = useRequest((page, ipp) => withdrawListAxios({ page, ipp }), {
        manual: true,
        onSuccess: (result, params) => {
            if (page.current === 1) {
                setTotal(result?.data?.total)
                customList.current = [...result?.data?.withdraws];
                ++page.current;
            }
        },
    });

    useEffect(() => {
        run(1, 10)
    }, [])

    const customLoadMore = async (done) => {
        if(onload){
            onload = false
            console.log("start", page.current )
            let res = await runAsync(page.current, 10);
            customList.current = [...customList.current, ...res?.data?.withdraws];
            ++page.current;
            if (total === customList.current.length || res?.data?.withdraws.length === 0) {
                setCustomHasMore(false)
                onload = false;
            }else{
                onload = true;
            }
            done()
        }
    }
    const onRefresh = (done) => {
        page.current = 1;
        onload = true;
        customList.current = [];
        setCustomHasMore(true);
        run(page.current, 10);
    }
    return (
        <ul id="customScroll" style={InfiniteUlStyle}>
            <PullToRefresh
                onRefresh={onRefresh}
            >

                <Infiniteloading
                    containerId="customScroll"
                    useWindow={false}
                    loadTxt="loading"
                    loadMoreTxt="没有啦～"
                    hasMore={customHasMore}
                    onLoadMore={customLoadMore}
                // isOpenRefresh={true}
                // onRefresh={onRefresh}
                >
                    {customList.current.map((item, index) => {
                        return (
                            <li key={index} style={InfiniteLiStyle}>
                                <div style={InfiniteLiItemStyle}>
                                    <p>
                                        <span>提现时间：</span>
                                        <span>{item?.CreatedAt}</span>
                                    </p>
                                    <p style={{ margin: "4px 0" }}>
                                        <span>状态：</span>
                                        <span>{withdrawalRecordStatus(item?.Status)}</span>
                                    </p>
                                    <div>
                                        <span>提现金额：</span>
                                        <Price price={item?.ReqMoney / 100} size="large" needSymbol thousands />
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                    {
                        customList.current.length === 0 && <Empty description="无数据" />
                    }
                </Infiniteloading>
            </PullToRefresh>

        </ul>
    )
}

export default memo(WithdrawalRecordComponent);