export const phoneVerity = (value) => {
    let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
    if (reg.test(value)) {
        return true;
    }
    return false;
}

export const CardInfo = (key) => {
    switch (key) {
        case "CCB":
            return "中国建设银行"
        case "BOC":
            return "中国银行"
        case "ABC":
            return "中国农业银行"
        case "ICBC":
            return "中国工商银行"
        case "CMBC":
            return "中国民生银行"
        case "CIB":
            return "兴业银行"
        case "HSBC":
            return "汇丰银行"
        case "PSBC":
            return "中国邮政储蓄银行"
        case "CITIC":
            return "中信银行"
        case "CDB":
            return "国家开发银行"
        case "COMM":
            return "交通银行"
        case "CMB":
            return "招商银行"
        case "HXBANK":
            return "华夏银行"
        case "GDB":
            return "广发银行"
        case "CMBC":
            return "中国民生银行"
        case "CEB":
            return "中国光大银行"
        case "EGBANK":
            return "恒丰银行"
        case "CZBANK":
            return "浙商银行	"
        case "BOHAIB":
            return "渤海银行"
        case "SPABANK":
            return "平安银行"
        case "NBBANK":
            return "宁波银行"
        case "NJCB":
            return "南京银行"
        case "HSBANK":
            return "徽商银行"
        case "CSCB":
            return "长沙银行"
        case "CDCB":
            return "成都银行"
        case "CQBANK":
            return "重庆银行"
        case "CSRCB":
            return "常熟农村商业银行"
        case "CZRCB":
            return "常州农村信用联社"
        case "H3CB":
            return "内蒙古银行"
        case "SXCB":
            return "绍兴银行"
        case "JSRCU":
            return "江苏省农村信用社联合社"
        default:
            return key;
    }
}

export const CardType = (key) => {
    switch (key) {
        case "DC":
            return "储蓄卡"
        case "CC":
            return "信用卡"
        case "SCC":
            return "准贷记卡"
        default:
            return key;
    }
}

// 获取地址栏参数
export const getUrlParam = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); // 匹配目标参数
    if (r != null) return decodeURI(r[2]);
    return null; // 返回参数值
}

export const serialization = (object, init) => {
    let str = init ?? "?";
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            if (str === "?") {
                str += `${key}=${object[key]}`
            } else {
                str += `&${key}=${object[key]}`
            }
        }
    }
    return str;
}

// 提现状态 转换
export const withdrawalRecordStatus = (status) => {
    switch (status) {
        case "0":
        case 0:
            return "申请中";
        case "1":
        case 1:
            return "打款中";
        case "2":
        case 2:
            return "提现拒绝";
        case "3":
        case 3:
            return "已完成(已打款)";

        default:
            break;
    }
}

export const fishPondStatus = (status) => {
    switch (status) {
        case "0":
        case 0:
            return "下线";
        case "1":
        case 1:
            return "公开审核中";
        case "2":
        case 2:
            return "公开";

        default:
            break;
    }
}


export const orderStatus = (value, type) => {
    switch (value) {
        case "2":
        case 2:
            return "待核销";
        case "3":
        case 3:
            return "已完成";
        case "8":
        case 8:
            return "已退单";

        default:
            return value;
    }
}

export const checkTelephone = (telephone) => {
    var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!reg.test(telephone)) {
        return false;
    } else {
        return true;
    }
}