import React, { memo, useState } from "react";
import { Form, Input, Cell, Button, Divider } from '@nutui/nutui-react'
import "./style.css";
import WXLOGIN from "@/static/image/wxlogin.png";
import { withRouter } from "react-router-dom";
const RegisterComponent = (props) => {
    const [codeLoading,setCodeLoading] = useState(false)
    const submitSucceed = () => {

    }
    const submitFailed = () => {

    }
    return (
        <div className="loginBox">
            <Form
                onFinish={(obj) => submitSucceed(obj)}
                onFinishFailed={(error) => submitFailed(error)}
                className="loginForm"
            >
                <h3>注册</h3>
                <Form.Item label='手机号/用户名' name="username">
                    <Input placeholder='手机号/用户名' type="text" />
                </Form.Item>

                <Form.Item className="code" label='手机验证码' name="number">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Input placeholder='手机验证码' type="number" style={{width:"80px"}} />
                        <Button type="primary">发送</Button>
                    </div>
                </Form.Item>

                <Form.Item label='密码' name="password">
                    <Input placeholder='密码' type="password" />
                </Form.Item>

                <Cell>
                    <Button className="loginBtn" shape="square" type="primary">注册</Button>
                </Cell>
                <p className="registeredAccess"  onClick={() => props.history.push("/login")}>已有账号？去登陆</p>
            </Form>
            {/* <div className="otherLogin">
                <Divider className="loginDivider"> or </Divider>
                <div className="otherLoginBtn">
                    <img src={WXLOGIN} style={{ width: "50px" }} />
                </div>
            </div> */}
        </div>
    )
}

export default memo(withRouter(RegisterComponent));